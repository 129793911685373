<template>
  <div>
    <div class="main-title">提现列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            ********
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l margin-t-sm">
          <el-select
            v-model="searchData.applier"
            clearable
            placeholder="申请人"
            size="small"
          >
            <el-option
              v-for="item in appliers"
              :key="item.id"
              :label="item.real_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-select
            v-model="searchData.status"
            clearable
            placeholder="状态"
            size="small"
          >
            <el-option label="待审核" value="0"> </el-option>
            <el-option label="通过" value="1"> </el-option>
            <el-option label="已打款" value="2"> </el-option>
            <el-option label="驳回" value="3"> </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-date-picker
            size="small"
            v-model="searchData.datePick"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd HH:mm:ss"
            unlink-panels
            range-separator="至"
            start-placeholder="点评日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          :cell-class-name="rowItem"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChange"
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column property="applier_name" label="申请人">
          </el-table-column>
          <el-table-column property="id_card" label="身份证">
          </el-table-column>
          <el-table-column property="mobile" label="手机号">
          </el-table-column>
          <el-table-column property="bank_account" label="银行卡号">
          </el-table-column>
          <el-table-column property="bank_name" label="银行支行">
          </el-table-column>
          <el-table-column property="amount" label="提现金额">
          </el-table-column>
          <el-table-column
            property="created_at"
            label="申请时间"
          ></el-table-column>
          <el-table-column
            property="audited_at"
            label="审核时间"
          ></el-table-column>
          <el-table-column
            property="paid_at"
            label="打款时间"
          ></el-table-column>
          <el-table-column label="状态" width="100">
            <template slot-scope="scope">
              <span class="state-down" v-if="scope.row.status == 0"
                >待审核</span
              >
              <span class="state-up" v-else-if="scope.row.status == 1"
                >通过</span
              >
              <span class="state-up" v-else-if="scope.row.status == 2"
                >已打款</span
              >
              <span lass="state-down" v-else-if="scope.row.status == 3"
                >驳回</span
              >
            </template>
          </el-table-column>

          <el-table-column property="address" label="操作">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 3">
                <el-popover
                  placement="top"
                  trigger="click"
                  :content="scope.row.reason"
                >
                  <el-link slot="reference" type="primary"
                    >查看驳回理由</el-link
                  >
                </el-popover>
              </span>
              <span
                v-if="scope.row.status == 0"
                @click="
                  dialogVisible = true;
                  form = {
                    applyId: scope.row.id,
                    isPass: 1,
                    reason: '',
                  };
                "
              >
                <el-link type="primary">审核</el-link>
              </span>
              <span v-if="scope.row.status == 1">
                <el-popover placement="top" width="160" v-model="visible">
                  <p>确定打款吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="apply(scope.row.id)"
                      >确定</el-button
                    >
                  </div>
                  <el-link slot="reference" type="primary">打款</el-link>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>

      <el-dialog
        title="审核"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
      >
        <el-form
          :model="form"
          status-icon
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="是否通过">
            <el-radio v-model="form.isPass" :label="1">通过</el-radio>
            <el-radio v-model="form.isPass" :label="0">驳回</el-radio>
          </el-form-item>
          <el-form-item label="驳回的理由" v-if="form.isPass == 0">
            <el-input type="textarea" v-model="form.reason"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm()"
              :loading="formLoading"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      tableData: [],
      searchData: {},
      appliers: [],
      loading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      visible: false,
      form: {
        applyId: null,
        isPass: 1,
        reason: "",
      },
      formLoading: false,
    };
  },
  methods: {
    ...mapActions("popularize", [
      "ListApplyForMoneyRecord",
      "selectListPromoter",
      "applyAudit",
      "applyPaid",
    ]),

    handleCurrentChange(val) {
      this.currentRow = val;
    },
    rowItem() {
      return "row-item";
    },
    async apply(id) {
      const { res_info } = await this.applyPaid(id);
      this.visible = false;
      if (res_info != "ok") return;
      this.getList();
      this.$message.success("打款成功");
    },
    async submitForm() {
      this.formLoading = true;
      const { res_info } = await this.applyAudit(this.form);
      this.formLoading = false;
      this.dialogVisible = false;
      if (res_info != "ok") return;
      this.$message.success("审核成功");
      this.getList();
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.ListApplyForMoneyRecord(form);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
    async getAoolier() {
      await this.getList();
      const { data } = await this.selectListPromoter();
      this.appliers = data;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
  },
  mounted() {
    this.getAoolier();
  },
};
</script>

<style>
.selectPic {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.selectPic p {
  float: left;
  width: 20%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.selectPic p:first-child {
  cursor: default;
}
</style>
